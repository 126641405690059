import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import PolicyStyled from "../components/styles/PolicyStyles"
import ShowHideItemPolicy from "../components/showHideItemPolicy"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { advertisingServicesPrivacyPolicySingleton } = data.swapi

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={advertisingServicesPrivacyPolicySingleton.metaTitle}
        description={advertisingServicesPrivacyPolicySingleton.metaDescription}
        img={
          advertisingServicesPrivacyPolicySingleton.metaImage
            ? advertisingServicesPrivacyPolicySingleton.metaImage.path
            : ""
        }
        lang="pl"
      />
      <div className="container">
        <PolicyStyled>
          <TitleStyled textAlign="center" linePosition="center" color="#000000">
            <h2
              dangerouslySetInnerHTML={{
                __html: advertisingServicesPrivacyPolicySingleton.title,
              }}
            ></h2>
          </TitleStyled>
          <div className="policy__container">
            {advertisingServicesPrivacyPolicySingleton.content.map(
              (content, index) => {
                return (
                  <div key={uuid()} className="policy__item">
                    <h2 className="policy__item__title">
                      <span>{content.value.heading}</span>
                    </h2>

                    <div className="showHideItem__elemtn policy__item__description">
                      <div>
                        {content.value.subTitle ? (
                          <p className="policy__item__subtitle">
                            {content.value.subTitle}
                          </p>
                        ) : null}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.value.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </PolicyStyled>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query advertisingServicesPrivacyPolicyPL {
    swapi {
      advertisingServicesPrivacyPolicySingleton(lang: "pl") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        title
        content {
          value
        }
      }
    }
  }
`
